<template>
	<div class="verification">
		<section class="request" v-if="artistVerificationRequest">
			<header class="request-header">
				<div class="informations">
					<div class="left_info">
						<div class="owner" v-if="artistVerificationRequest.owner">
							<v-avatar :width="50" :height="50" :min-width="50">
								<v-img
									lazy-src="https://via.placeholder.com/40"
									:src="ownerAvatar"
									:alt="ownerFullName"
								></v-img>
							</v-avatar>
							<div class="owner_data">
								<div class="user" @click.stop="redirectToUserProfile(artistVerificationRequest.owner.id, ownerFullName)">
									<span>User:</span>
									<div class="link">{{ ownerFullName }}</div>
								</div>
								<div class="email">
									<span>Email:</span> {{ artistVerificationRequest.email }}
								</div>
							</div>
						</div>
					</div>

					<div class="requests">
						<!-- Requests: <span>2</span>-->
					</div>
					<div class="creation-date">
						Received: {{ artistVerificationRequest.createdAt | moment("MMM DD, YYYY") }}
					</div>
				</div>
				<v-menu
					transition="slide-y-transition"
					:close-on-content-click="true"
					:nudge-width="230"
					offset-y
					nudge-left="220"
					nudge-bottom="10"
					origin="top center"
					content-class="menu_admin verification-menu">
					<v-list class="admin_menu verification-menu-list" dense>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'pending'}" @click="mark('pending')" v-ripple>
							<v-list-item-icon>
								<span class="material-icons">pending</span>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title class="menu-item-title">Mark Pending</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'approved'}" @click="mark('approved')"  v-ripple>
							<v-list-item-icon>
								<span class="material-icons">check_circle</span>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title class="menu-item-title">Approve</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'denied'}"  @click="mark('denied')" v-ripple>
							<v-list-item-icon>
								<span class="material-icons">cancel</span>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title class="menu-item-title">Deny</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'banned'}"  @click="mark('banned')" v-ripple>
							<v-list-item-icon>
								<span class="material-icons">block</span>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title class="menu-item-title">Ban user</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<template v-slot:activator="{ on, attrs }">
						<div class="actions-menu" v-bind="attrs" v-on="on" v-ripple>
							<span class="material-icons">more_vert</span>
						</div>
					</template>
				</v-menu>
			</header>
			<div class="request-body">
				<h4>Account Requested</h4>
				<div class="artist" v-if="artistVerificationRequest.artist">
					<v-avatar :width="50" :height="50" :min-width="50">
						<v-img
							lazy-src="https://via.placeholder.com/40"
							:src="artistAvatar"
							:class="{'default': !ARTIST_VERIFICATION_REQUEST.artist.avatar }"
							:alt="artistVerificationRequest.artist.name"
						></v-img>
					</v-avatar>
					<div class="link" @click="redirectToArtistPage(artistVerificationRequest.artist.id, artistVerificationRequest.artist.identifier)">
                        {{ artistVerificationRequest.artist.name }}
                    </div>
				</div>
				<h4>Uploaded Documents</h4>

				<ul class="documents-list">
					<li>
						<preview-image
							:width="135"
							:height="135"
							:src="getImageUrl(artistVerificationRequest.idCardPhoto)"
						></preview-image>
					</li>

					<li>
						<preview-image
							:width="135"
							:height="135"
							:src="getImageUrl(artistVerificationRequest.selfPhoto)"
						></preview-image>
					</li>
				</ul>

				<h4>Links</h4>
				<ul v-if="links.length > 0" class="links-list">
					<li v-for="link in links" v-bind:key="link">
						<div class="link" @click="redirectToLink(link)">{{ link }}</div>
					</li>
				</ul>
				<h4>User Comments</h4>
				<p class="user-comments" v-html="nl2br(artistVerificationRequest.comments)">
				</p>
			</div>
		</section>
		<verification-comments v-if="artistVerificationRequest" :verification-request="artistVerificationRequest"></verification-comments>
		<div class="page-loader">
			<v-progress-circular v-if="loader" :size="32" :width="3" color="#20C4F5" indeterminate></v-progress-circular>
		</div>
	</div>
</template>

<script>
import verificationComments from "@/components/manage/verificationComments";
import {mapActions, mapGetters} from "vuex";
import PreviewImage from "@/components/manage/previewImage";
import ModalImage from "@/components/public/modalImage";

export default {
	name: 'verification',
	props: ['id'],
	components: {
		PreviewImage,
		verificationComments,
		ModalImage
	},
	data() {
		return {
			loader: true,
			artistVerificationRequest: null,
			status: null,
			stats: {},
			comments: '',
			apiUrl: process.env.VUE_APP_API_URL,
		}
	},
	created() {
		this.GET_ARTIST_VERIFICATION_REQUEST(this.id)
			.then(() => {
				this.artistVerificationRequest = this.ARTIST_VERIFICATION_REQUEST;
			})
			.catch(err => {
				console.log(`GET_ARTIST_VERIFICATION_REQUEST, ${err}`);
			})
			.finally(() => {
				this.loader = false;
			});
	},
	methods: {
		...mapActions([
			'GET_ARTIST_VERIFICATION_REQUEST',
			'PATCH_ARTIST_VERIFICATION_REQUEST'
		]),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			const routeData = this.$router.resolve({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
			window.open(routeData.href, '_blank');
		},
		redirectToUserProfile(userID, userName) {
			const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
			window.open(routeData.href, '_blank');
		},
		redirectToLink(link) {
			// window.location.href = this.CREATE_LINK_STRIPE_ACCOUNT;
			let formattedLink = link;
			if (!link.includes('http://') && !link.includes('https://')) {
				formattedLink = 'https://' + link;
			}
			window.open(formattedLink, '_blank');
		},
		getImageUrl(mediaObject) {
			if (!mediaObject) {
				return '';
			}

			// For verification images, use the full contentUrl directly
			if (mediaObject.contentUrl && mediaObject.contentUrl.includes('/verification/')) {
				// Check if the URL is already absolute
				if (mediaObject.contentUrl.startsWith('http')) {
					return mediaObject.contentUrl;
				}
				// If it's a relative URL, prepend the CDN URL
				return process.env.VUE_APP_CDN_URL + mediaObject.contentUrl;
			}

			// Use API URL for other images
			return this.apiUrl + mediaObject.contentUrl;
		},
		mark(status) {
			console.log('status', status);
			if (this.artistVerificationRequest.status === status) {
				return;
			}
			this.loader = true;
			this.PATCH_ARTIST_VERIFICATION_REQUEST({id: this.id, status: status})
				.then(() => {
					let index = this.ARTIST_VERIFICATION_REQUESTS.findIndex((request) => +request.id === this.id);
					if (index !== -1) {
						this.ARTIST_VERIFICATION_REQUESTS.splice(index, 1);
					}

					this.artistVerificationRequest.status = status;
					// trigger event

					this.$emit('verification_status_updated', this.artistVerificationRequest);
				})
				.catch(err => {
					console.error('PATCH_ARTIST_VERIFICATION_REQUEST', err);
				})
				.finally(() => {
					this.loader = false;
				});
		},
		nl2br(str) {
			if (typeof str === 'undefined' || str === null) {
				return '';
			}

			return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
		}
	},
	computed: {
		...mapGetters([
			'ARTIST_VERIFICATION_REQUEST',
			'ARTIST_VERIFICATION_REQUESTS'
		]),
		artistAvatar() {
			if (!this.ARTIST_VERIFICATION_REQUEST.artist.avatar) {
				return require('@/assets/image/svg/square_avatar.svg');
			}

			return this.getImageUrl(this.ARTIST_VERIFICATION_REQUEST.artist.avatar);
		},
		ownerAvatar() {
			if (!this.artistVerificationRequest?.owner?.photo?.thumbnail?.['50x50']) {
				return require('@/assets/image/svg/square_avatar.svg');
			}
			return this.artistVerificationRequest.owner.photo.thumbnail['50x50'];
		},
		links() {
			return this.nl2br(this.artistVerificationRequest.links).split('<br>');
		},
		ownerFullName() {
			return this.artistVerificationRequest?.owner?.fullName || 'Unknown User';
		},
	}
}
</script>

<style lang="scss">
@import "@/assets/styles/pages/_admin_verifications.scss";
</style>
