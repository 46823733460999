<template>
	<supportLayout>
		<div class="p-verifications full_page">
			<div class="container">
				<aside>
					<verifications-filter :refresh="refreshFilter" :active-status="status"></verifications-filter>
				</aside>
				<main :class="{is_expanded: expanded.length, zoomed_img: ZOOMED_IMG}">
					<v-data-table
						:headers="headers"
						:items="requests"
						:options.sync="options"
						:server-items-length="COUNT_ARTIST_VERIFICATION_REQUESTS"
						:loading="loading"
						:single-expand="singleExpand"
						:expanded.sync="expanded"
						@click:row="rowClicked"
						:class="{is_expanded: expanded.length, zoomed_img: ZOOMED_IMG}"
						class="admin-table">

						<template v-slot:item.owner.fullName="{ item }">
							{{ getUserName(item) }}
						</template>

						<template v-slot:item.status="{ item }">
							<v-chip :color="getColor(item.status)" dark>
								{{ item.status }}
							</v-chip>
						</template>

						<template v-slot:item.createdAt="{ item }">
							{{ item.createdAt | moment("MM/DD/YYYY") }}
						</template>

						<template v-slot:expanded-item="{ headers, item }">
							<td :colspan="headers.length" class="item-details" :class="{is_expanded: expanded.length, zoomed_img: ZOOMED_IMG}">
								<verification @verification_status_updated="verificationUpdated" :id="item.id"></verification>
							</td>
						</template>

						<template v-slot:footer.page-prev>
							<span class="material-icons-outlined">keyboard_arrow_left</span>
						</template>
						<template v-slot:footer.page-next>
							<span class="material-icons-outlined">keyboard_arrow_right</span>
						</template>
					</v-data-table>
				</main>
			</div>
		</div>
	</supportLayout>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import supportLayout from "@/layouts/supportLayout.vue";
import VerificationsFilter from "@/components/manage/verificationsFilter";
import Verification from "@/components/manage/verification";

export default {
	name: "verifications",
	props: ['status'],
	components: {
		supportLayout,
		Verification,
		VerificationsFilter,
	},
	data: () => ({
		apiUrl: process.env.VUE_APP_API_URL,
		requests: [],
		totalRequests: 0,
		loading: true,
		refreshFilter: 0,
		options: {
			itemsPerPage: 10,
			sortDesc: [true],
			sortBy: ['createdAt']
		},
		singleExpand: false,
		expanded: [],
		headers: [
			{text: 'User Name', value: 'owner.fullName'},
			{text: 'Email', value: 'email'},
			{text: 'Artist', value: 'artist.name'},
			{text: 'Status', value: 'status'},
			{text: 'Date', value: 'createdAt'},
		],
	}),
	computed: {
		...mapGetters([
			'ARTIST_VERIFICATION_REQUEST',
			'ARTIST_VERIFICATION_REQUESTS',
			'COUNT_ARTIST_VERIFICATION_REQUESTS',
			'ZOOMED_IMG'
		]),
	},
	watch: {
		status: {
			handler() {
				this.getDataFromApi();
				this.expanded = [];
			},
		},
		options: {
			handler() {
				this.getDataFromApi()
			},
			deep: true,
		}
	},
	mounted() {

	},
	methods: {
		...mapActions([
			'GET_ARTIST_VERIFICATION_REQUESTS', 
			'GET_ARTIST_VERIFICATION_REQUEST'
		]),
		getApiParams() {
			// this.options = { sortBy, sortDesc, page, itemsPerPage }
			const params = {
				page: this.options.page,
				itemsPerPage: this.options.itemsPerPage < 0 ? 99999 : this.options.itemsPerPage,
			}

			for (let i = 0; i < this.options.sortBy.length; i++) {
				params[`order[${this.options.sortBy[i]}]`] = this.options.sortDesc[i] === true ? 'desc' : 'asc';
			}

			if (this.status) {
				if (this.status.toLowerCase() === 'completed') {
					params['status'] = ['approved', 'denied', 'banned'];
				} else {
					params['status'] = this.status.toLowerCase();
				}
			}

			return params;
		},
		getDataFromApi() {
			this.loading = true

			this.GET_ARTIST_VERIFICATION_REQUESTS(this.getApiParams())
				.then(() => {
					this.loading = false;
					this.requests = this.ARTIST_VERIFICATION_REQUESTS;
				})
				.catch(err => {
					this.loading = false;
					console.log(`GET_ARTIST_VERIFICATION_REQUESTS, ${err}`);

				})
		},
		getColor(status) {
			switch (status) {
				case 'banned':
				case 'denied':
					return 'red';
				case 'pending':
					return 'orange';
				case 'approved':
				case 'completed':
					return 'green';
				default:
					return 'grey';
			}
		},
		getImageUrl(mediaObject) {
			if (!mediaObject) {
				return '';
			}

			// For verification images, use the full contentUrl directly
			if (mediaObject.contentUrl && mediaObject.contentUrl.includes('/verification/')) {
				// Check if the URL is already absolute
				if (mediaObject.contentUrl.startsWith('http')) {
					return mediaObject.contentUrl;
				}
				// If it's a relative URL, prepend the CDN URL
				return process.env.VUE_APP_CDN_URL + mediaObject.contentUrl;
			}

			// Use API URL for other images
			return this.apiUrl + mediaObject.contentUrl;
		},
		getUserName(item) {
			if (!item?.owner?.fullName) return 'Name not found';
			return item.owner.fullName;
		},
		rowClicked(item, row) {
			row.expand(!row.isExpanded);
			// console.log('item', item);
			// console.log('row', row);
			// this.expanded = [item.id];
		},
		verificationUpdated(verificationRequest) {
			this.refreshFilter++;

			this.requests.forEach(item => {
				if (item.id === verificationRequest.id) {
					// Update both status and owner information
					item.status = verificationRequest.status;
					if (verificationRequest.owner) {
						item.owner = verificationRequest.owner;
					}
				}
			});

			// refresh admin notification on header
			this.$store.dispatch('GET_ADMIN_NOTIFICATIONS');
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/styles/global/icons/_formComponents.scss";
@import "@/assets/styles/pages/_admin_verifications.scss";
</style>

